<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item
              :to="{
                name: 'CustomerList',
              }"
              >會員列表</b-breadcrumb-item
            >
            <b-breadcrumb-item active>受眾管理</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
        <h4 class="col-12 col-xl-6 mb-2 mb-xl-0 font-weight-bold">受眾管理</h4>
        <div
          class="
            col-12 col-xl-6
            d-flex
            flex-column flex-xl-row
            align-items-start align-items-xl-center
          "
        >
          <b-button            
            class="flex-shrink-0 mb-2 mb-xl-0"
            variant="primary"
            :to="{ name: 'AudienceCreate' }"
            ><i class="fa fa-plus"></i>新增受眾</b-button
          >

          <b-input-group class="ml-0 ml-xl-4">
            <b-form-input
              v-model="keyword"
              v-on:keyup.enter="getAudiences(1)"
              placeholder="搜尋"
            ></b-form-input>
            <b-input-group-append>
              <b-button @click="getAudiences(1)"
                ><i class="fa fa-search"></i
              ></b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>
      <b-card-text>
        <div class="row">
          <div class="col-12">           
            <b-table
              striped
              hover
              responsive
              :items="audiences"
              :fields="fields"
              :busy="isLoading"
              no-local-sorting
            >
              <template #table-busy>
                <div class="text-center my-2">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
              </template>
              <template #cell(actions)="data">
                <b-button
                  v-if="checkPermission([consts.AUDIENCE_LIST_VIEW])"
                  variant="inverse-primary"
                  :to="{
                    name: 'AudienceView',
                    params: {
                      audienceId: data.item.id,
                    },
                  }"
                  >查看</b-button>
                <b-button
                  v-if="checkPermission([consts.AUDIENCE_MODIFY])"
                  class="ml-2"
                  variant="inverse-warning"
                  :to="{
                    name: 'AudienceEdit',
                    params: {
                      audienceId: data.item.id,
                    },
                  }"
                  >編輯</b-button>
                <b-button
                  v-if="checkPermission([consts.AUDIENCE_DELETE])"
                  class="ml-2"
                  variant="danger"
                  @click="handleDelete(data.item.id, data.item.title)"
                  >刪除</b-button>
                <b-dropdown  variant="inverse-secondary" class="ml-2">
                  <template slot="button-content">
                    <i class="mdi mdi-menu"></i>
                  </template>
                  <b-dropdown-item
                    v-if="checkPermission([consts.AUDIENCE_LIST_VIEW])"
                    class="ml-2"
                    variant="inverse-secondary"
                    :to="{
                      name: 'AudienceCustomersList',
                      params: {
                        audienceId: data.item.id,
                      },
                    }"
                    >受眾名單</b-dropdown-item>
                  <b-dropdown-item
                    v-if="checkPermission([consts.AUDIENCE_MODIFY])"
                    class="ml-2"
                    @click="handleSync(data.item.id, data.item.title)"
                    ><i class="fa fa-repeat"> </i> 更新名單</b-dropdown-item>
                  <b-dropdown-item
                    v-if="checkPermission([consts.AUDIENCE_MODIFY])"
                    class="ml-2"
                    @click="handleSyncToLine(data.item.id, data.item.title)"
                    >同步至LINE</b-dropdown-item>
                    <b-dropdown-item
                      class="ml-2"
                      :to="{
                        name: 'PushMessageDetail',
                        params: {
                          messageID: 'create',
                        },
                        query: {
                          audience_id: data.item.id,
                        }
                      }"
                      >群發訊息</b-dropdown-item>
                </b-dropdown>

              </template>
            </b-table>
          </div>
        </div>
        <div class="d-flex justify-content-center" style="margin-top: 80px">
          <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="center"
              @change="getAudiences"
          ></b-pagination>
        </div>
      </b-card-text>
    </b-card>
  
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import PermissionChecker from "@/utils/PermissionChecker"
import * as consts from "@/consts";
import audienceApi from "@/apis/audience"
import moment from "moment"

export default {
  data() {
    return {
      consts: consts,
      isLoading: false,
      audiences: [],
      fields: [
        {
          key: "title",
          label: "標題",
        },
        {
          key: "source",
          label: "受眾來源",
          formatter(source) {
            switch (source) {
              case 'filter':
                return '條件查詢'
              case 'import':
                return '手動匯入'
            }
          }
        },
        {
          key: "count",
          label: "數量",
        },
        {
          key: "status",
          label: "狀態",
          formatter(status) {
            switch (status) {
              case 'waiting':
                return '已排程'
              case 'processing':
                return '處理中'
              case 'sync-to-line':
                return '同步至Line'
              case 'done':
                return '已完成'
              case 'failed':
                return '同步失敗'
              case 'import-failed':
                return '匯入失敗' 
              case 'sync-line-failed':
                return '同步至 LINE 失敗'
            }
          }
        },
        {
          key: "synced_at",
          label: "最後更新時間",
          formatter(synced_at) {
            return moment(synced_at).format('yyyy-MM-DD HH:mm:ss')
          }
        },
        {
          key: "created_at",
          label: "建立時間",
          formatter: (value) => {
            return moment(value).isValid()
              ? moment(value).format("YYYY-MM-DD HH:mm:ss")
              : undefined
          },
          sortable: true,
          sortKey: "created_at"
        },
        {
          key: "actions",
          label: "管理",
        },
      ],
      currentPage: 1,
      perPage: 15,
      totalRows: 1,
      showLoading: false,
      showLoadingUpload: false,      
      keyword: null,
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    ...mapGetters('general', ['branchOptions']),
  },
  mounted() {
    this.init();
  },
  methods: {
    checkPermission(permissions) {
      const checker = new PermissionChecker();
      return checker.check(permissions);
    },
    async init() {
      if (!this.checkPermission([consts.AUDIENCE_LIST_VIEW])) {
        this.$swal
          .fire({
            type: "error",
            text: "你沒有權限訪問此頁面",
            confirmButtonColor: "#d33",
          })
          .then(() => {
            this.$router.push({ name: "OrganizationList" });
          });
      } else {
        await this.getAudiences();
      }
    },
    async handleSyncToLine(autoawardId, title) {
      this.$swal
        .fire({
          type: "warning",
          title: "同步受眾到 LINE？",
          html: `
            <div class="d-block">
              <div class="my-3">
                <div>確定要同步受眾 ${title} 嗎?</div>
              </div>
            </div>`,
          confirmButtonText: "更新",
          cancelButtonText: "不更新",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value) {
            try {
              this.isLoading = true;
              await audienceApi.syncAudience(autoawardId);
              await this.getAudiences();
            } catch (error) {
              console.log(error);
            } finally {
              this.isLoading = false;
            }
          }
        })
    },
    async handleSync(autoawardId, title) {
      this.$swal
        .fire({
          type: "warning",
          title: "更新受眾？",
          html: `
            <div class="d-block">
              <div class="my-3">
                <div>確定要更新受眾 ${title} 嗎?</div>
              </div>
            </div>`,
          confirmButtonText: "更新",
          cancelButtonText: "不更新",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value) {
            try {
              this.isLoading = true;
              await audienceApi.syncAudience(autoawardId);
              await this.getAudiences();
            } catch (error) {
              console.log(error);
            } finally {
              this.isLoading = false;
            }
          }
        })
    },
    async handleDelete(autoawardId, title) {
      this.$swal
        .fire({
          type: "warning",
          title: "你確定要刪除嗎？",
          html: `
            <div class="d-block">
              <div class="my-3">
                <div>確定要刪除受眾${title} ?</div>
              </div>
              <div>刪除後，相關資料將無法回復，<br/>請您確認！</div>
            </div>`,
          confirmButtonText: "確定刪除",
          cancelButtonText: "不刪除",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value) {
            try {
              this.isLoading = true;
              await audienceApi.deleteAudience(autoawardId);
              await this.getAudiences();
            } catch (error) {
              console.log(error);
            }
            this.isLoading = false;
          }
        });
    },

    async getAudiences(page = 1, orderBy = '', orderDesc = true) {
      try {
        this.isLoading = true;
        const { data } = await audienceApi.getAudiences(
          { page, order_by: orderBy, order_direction: orderDesc ? 'desc' : 'asc', per_page: this.perPage  }
        );
        this.totalRows = data.meta.total;
        this.perPage = data.meta.per_page;
        this.audiences = data.data;
        console.log("loaded", this.audiences)
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.modal-backdrop {
  opacity: 0.5;
}

.advance-search {
  color: #337ab7;

  &:hover {
    color: #23527c;
    text-decoration: underline;
  }
}
</style>
