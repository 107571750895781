var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row page-title-header"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"page-header"},[_c('b-breadcrumb',{staticClass:"m-0"},[_c('b-breadcrumb-item',{attrs:{"to":{
              name: 'DashboardHome',
            }}},[_c('i',{staticClass:"fa fa-home"})]),_c('b-breadcrumb-item',{attrs:{"to":{
              name: 'CustomerList',
            }}},[_vm._v("會員列表")]),_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v("受眾管理")])],1)],1)])]),_c('b-card',[_c('div',{staticClass:"row d-flex mb-4 mb-xl-2 justify-content-between"},[_c('h4',{staticClass:"col-12 col-xl-6 mb-2 mb-xl-0 font-weight-bold"},[_vm._v("受眾管理")]),_c('div',{staticClass:"\n          col-12 col-xl-6\n          d-flex\n          flex-column flex-xl-row\n          align-items-start align-items-xl-center\n        "},[_c('b-button',{staticClass:"flex-shrink-0 mb-2 mb-xl-0",attrs:{"variant":"primary","to":{ name: 'AudienceCreate' }}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v("新增受眾")]),_c('b-input-group',{staticClass:"ml-0 ml-xl-4"},[_c('b-form-input',{attrs:{"placeholder":"搜尋"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getAudiences(1)}},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}}),_c('b-input-group-append',[_c('b-button',{on:{"click":function($event){return _vm.getAudiences(1)}}},[_c('i',{staticClass:"fa fa-search"})])],1)],1)],1)]),_c('b-card-text',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('b-table',{attrs:{"striped":"","hover":"","responsive":"","items":_vm.audiences,"fields":_vm.fields,"busy":_vm.isLoading,"no-local-sorting":""},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center my-2"},[_c('b-spinner',{staticClass:"align-middle"})],1)]},proxy:true},{key:"cell(actions)",fn:function(data){return [(_vm.checkPermission([_vm.consts.AUDIENCE_LIST_VIEW]))?_c('b-button',{attrs:{"variant":"inverse-primary","to":{
                  name: 'AudienceView',
                  params: {
                    audienceId: data.item.id,
                  },
                }}},[_vm._v("查看")]):_vm._e(),(_vm.checkPermission([_vm.consts.AUDIENCE_MODIFY]))?_c('b-button',{staticClass:"ml-2",attrs:{"variant":"inverse-warning","to":{
                  name: 'AudienceEdit',
                  params: {
                    audienceId: data.item.id,
                  },
                }}},[_vm._v("編輯")]):_vm._e(),(_vm.checkPermission([_vm.consts.AUDIENCE_DELETE]))?_c('b-button',{staticClass:"ml-2",attrs:{"variant":"danger"},on:{"click":function($event){return _vm.handleDelete(data.item.id, data.item.title)}}},[_vm._v("刪除")]):_vm._e(),_c('b-dropdown',{staticClass:"ml-2",attrs:{"variant":"inverse-secondary"}},[_c('template',{slot:"button-content"},[_c('i',{staticClass:"mdi mdi-menu"})]),(_vm.checkPermission([_vm.consts.AUDIENCE_LIST_VIEW]))?_c('b-dropdown-item',{staticClass:"ml-2",attrs:{"variant":"inverse-secondary","to":{
                    name: 'AudienceCustomersList',
                    params: {
                      audienceId: data.item.id,
                    },
                  }}},[_vm._v("受眾名單")]):_vm._e(),(_vm.checkPermission([_vm.consts.AUDIENCE_MODIFY]))?_c('b-dropdown-item',{staticClass:"ml-2",on:{"click":function($event){return _vm.handleSync(data.item.id, data.item.title)}}},[_c('i',{staticClass:"fa fa-repeat"}),_vm._v(" 更新名單")]):_vm._e(),(_vm.checkPermission([_vm.consts.AUDIENCE_MODIFY]))?_c('b-dropdown-item',{staticClass:"ml-2",on:{"click":function($event){return _vm.handleSyncToLine(data.item.id, data.item.title)}}},[_vm._v("同步至LINE")]):_vm._e(),_c('b-dropdown-item',{staticClass:"ml-2",attrs:{"to":{
                      name: 'PushMessageDetail',
                      params: {
                        messageID: 'create',
                      },
                      query: {
                        audience_id: data.item.id,
                      }
                    }}},[_vm._v("群發訊息")])],2)]}}])})],1)]),_c('div',{staticClass:"d-flex justify-content-center",staticStyle:{"margin-top":"80px"}},[_c('b-pagination',{attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage,"align":"center"},on:{"change":_vm.getAudiences},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }